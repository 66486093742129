import React, { Component } from 'react';

import { properties } from './properties.js';
import './App.css';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentGuess: 0,
      currentLetter: 0,
      guesses: [
        ["", "", "", "", ""],
      ],
      rightAnswer: -1,
      hasWon: false,
      hasLost: false,
    };
  }

  componentDidMount() {
    document.title = this.getVersionString();
    this.restartGame();

    // listen for key input
    window.addEventListener('keydown', (event) => {
      if (!event.altKey && !event.metaKey && !event.ctrlKey) {
        this.handleKeypress(event.key);
      }
    });
  }

  getVersionString() {
    return "HiRDLE " + properties.version;
  }

  isValidWord(s) {
    if (properties.dict.indexOf(s.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }

  isCorrectWord(s) {
    if (s.toLowerCase() === properties.dict[this.state.rightAnswer]) {
      return true;
    }
    return false;
  }

  handleKeypress(key) {
    let ch = key;

    let guesses = this.state.guesses;
    let g = this.state.currentGuess;
    let n = this.state.currentLetter;

    // backspace?
    if (ch === "Backspace" && n > 0) {
      guesses[g][n - 1] = "";
      this.setState({
        currentLetter: n - 1,
        guesses: guesses,
      });
      return;
    }

    // enter?
    else if (ch === "Enter") {
      if (this.state.hasWon || this.state.hasLost) {
        this.restartGame();
        return;
      }
      else if (n === 5 && g < 6) {
        let guess = guesses[g].join("");
        if (this.isCorrectWord(guess)) {
          this.setState({ hasWon: true, hasLost: false });
          return;
        }
        else if (g === 5) {
          this.setState({ hasLost: true, hasWon: false });
          return;
        }

        guesses.push(["", "", "", "", ""]);
        this.setState({
          currentGuess: g + 1,
          guesses: guesses,
          currentLetter: 0,
        });
        return;
      }
    }

    ch = ch.trim();
    if (ch.length > 1 || ch.length === 0) {
      return;
    }

    // normal letter?
    if (n >= 5) {
      return;
    }

    guesses[g][n] = ch;
    n++;
    this.setState({
      currentLetter: n,
      guesses: guesses,
    });
  }

  renderKeyboard() {
    let keyboard = [
      ["Q", "W", "E", "R", "T", "Y", "U", "i", "O", "P"],
      ["A", "S", "D", "F", "G", "H", "I", "J", "K", "L"],
      ["Backspace", "Z", "X", "C", "V", "B", "N", "M", "Enter"],
    ];
    return (
      <div className="GameKeyboard">
        {
          keyboard.map((rowData, rowIdx) => {
            return (
              <div className="GameKeyboardRow" key={rowIdx}>
                {
                  rowData.map((key, colIdx) => {
                    return (
                      <div className="GameKeyboardKey" key={key} onClick={() => this.handleKeypress(key)}>{
                        key === "Backspace" ? "⌫" : key === "Enter" ? "↵" : key
                      }</div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  getScrollText() {
    let n = Math.floor(Math.random() * properties.scrollTexts.length);
    return properties.scrollTexts[n];
  }

  restartGame() {
    let n = properties.dict.length;
    this.setState({
      rightAnswer: Math.floor(Math.random() * n),
      guesses: [["", "", "", "", ""]],
      currentGuess: 0,
      currentLetter: 0,
      hasWon: false,
      hasLost: false,
      scrollText: this.getScrollText(),
    });
  }

  getLetterClass(row, col) {
    if (this.state.hasWon && row === this.state.currentGuess) {
      return "Letter Correct";
    }
    if (this.state.rightAnswer === -1 || row >= this.state.currentGuess) {
      return "Letter";
    }
    let g = this.state.guesses;
    let ch = g[row][col];
    let answer = properties.dict[this.state.rightAnswer];
    if (ch === answer[col]) {
      return "Letter Correct";
    }
    else if (answer.indexOf(ch) !== -1) {
      return "Letter WrongPlace";
    }
    return "Letter";
  }
  
  renderGameScreen() {
    return (
      <div className="GameContainer">
        <div className="GameScreen">
          {
            this.state.guesses.map((value, row) => {
              return (
                <div className="GameRow" key={row}>
                  {
                    value.map((letter, column) => {
                      return (
                        <div className={this.getLetterClass(row, column)} key={column}>{
                          letter === 'i' ? 'i' : letter === 'e' ? '3' : letter.toUpperCase()
                        }</div>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="App">
        <div className="GameTitle"><img src="hirdle-logo2.png" alt="{getVersionString()}"/>
          <marquee className="Scroller">{this.state.scrollText}</marquee>
        </div>        
        {this.renderGameScreen()}
        {this.state.hasWon && <div className="Gameover Victory">YOU WiN BUT HiRMU STiLL RUl3Z TH3 SC3N3!</div>}
        {this.state.hasLost && <div className="Gameover">YOU ARE CASS377E L4M3R! SEND ST4MP5 B4CK!</div>}
        {this.renderKeyboard()}
        <div className="GameFooter">
          COPYRiGHT AND COPYL3FT &copy; HiRMU ENT3RPRiSES 2022 AND B3Y0ND!<br />
          POW3R3D BY 104% MACHiNE C0DE
        </div>
      </div>
    );
  }
}

export default App;
