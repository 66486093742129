export const properties = {
    version: "1.4.1",
    dict: [
        "hirmu",
        "rulez",
    ],
    scrollTexts: [
        "fuckings to all lamers!",
        "send stamps back!",
        "greetings to ACCESSiON",
        "powered by 102% machine code!",
    ]
}